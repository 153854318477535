import { toast } from "react-toastify";
import {
  //
  CAREER_ADD_REQUEST,
  CAREER_ADD_SUCCESS,
  CAREER_ADD_FAIL,
  //
} from "../constants/careerConstants";

export const createInscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case CAREER_ADD_REQUEST:
      return { loadingCareerAdd: true };
    case CAREER_ADD_SUCCESS:
      toast.success("Your demand has been submitted successfully.");
      return {
        loadingCareerAdd: false,
        successCareerAdd: true,
      };
    case CAREER_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingCareerAdd: false,
        successCareerAdd: false,
        errorCareerAdd: action.payload,
      };
    default:
      return state;
  }
};
