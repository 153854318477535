import React from "react";

import bgHome from "../assets/images/bg-home-null.jpg";
import aynnakaLogo from "../assets/images/aynnaka-logo.png";
import iconHiring from "../assets/images/icon-hiring.png";
import buttonApply from "../assets/images/button-apply.png";

function HomeScreen() {
  return (
    <div className="w-screen h-screen">
      <div
        className=" bg-no-repeat w-screen h-screen flex flex-col justify-between items-center"
        style={{ background: 'url("' + bgHome + '")', backgroundSize: "cover" }}
      >
        {/* logo */}
        <a
          href="/"
          className="w-screen flex flex-row items-center justify-center"
        >
          <img src={aynnakaLogo} className="h-20" />
        </a>

        {/* icon */}
        <div></div>
        <img src={iconHiring} className="mx-auto " />
        <div></div>

        {/* apply */}
        <a href="/apply-careers" className="md:w-1/4 w-1/2">
          <img src={buttonApply} className="mx-auto w-full" />
        </a>
        <div className="md:h-4 h-10"></div>
      </div>
    </div>
  );
}

export default HomeScreen;
