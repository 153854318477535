import React, { useEffect, useState } from "react";

import bgHome from "../assets/images/bg-home-null.jpg";
import aynnakaLogo from "../assets/images/aynnaka-logo.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNewInscription } from "../redux/actions/careerActions";

function ApplyCareerScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [fileCV, setFileCV] = useState("");
  const [fileCVValue, setFileCVValue] = useState("");
  const [fileCVError, setFileCVError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const inscriptionCareer = useSelector((state) => state.createInscription);
  const { loadingCareerAdd, errorCareerAdd, successCareerAdd } =
    inscriptionCareer;

  useEffect(() => {
    if (successCareerAdd) {
      setFirstName("");
      setFirstNameError("");
      setLastName("");
      setLastNameError("");
      setPhone("");
      setPhoneError("");
      setEmail("");
      setEmailError("");
      setFileCVValue("");
      setFileCV("");
      setFileCVError("");
      setRole("");
      setRoleError("");
      //   navigate("/order-received/");
    }
  }, [successCareerAdd]);

  return (
    <div className="w-screen min-h-screen">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div
        className=" bg-no-repeat w-screen min-h-screen flex flex-col items-center"
        style={{ background: 'url("' + bgHome + '")', backgroundSize: "cover" }}
      >
        {/* logo */}

        <a
          href="/"
          className="w-screen flex flex-row items-center justify-center"
        >
          <img src={aynnakaLogo} className="h-20" />
        </a>
        <div className="h-20"></div>

        <div className="container mx-auto md:w-1/2 w-full px-5">
          <div className="my-2 flex flex-col">
            <label className="font-semibold">
              First Name <strong className="text-red-500">*</strong>
            </label>
            <input
              type="text"
              value={firstName}
              onChange={(v) => setFirstName(v.target.value)}
              className={`border rounded px-3 py-2 text-sm text-[#140083] outline-none ${
                firstNameError ? "border-red-500" : ""
              } `}
              placeholder="Your First Name"
            />
            <div className="text-xs text-red-500 mt-1">{firstNameError}</div>
          </div>
          <div className="my-2 flex flex-col">
            <label className="font-semibold">Last Name </label>
            <input
              type="text"
              value={lastName}
              onChange={(v) => setLastName(v.target.value)}
              className={`border rounded px-3 py-2 text-sm text-[#140083] outline-none ${
                lastNameError ? "border-red-500" : ""
              } `}
              placeholder="Your Last Name"
            />
            <div className="text-xs text-red-500 mt-1">{lastNameError}</div>
          </div>
          <div className="my-2 flex flex-col">
            <label className="font-semibold">
              Phone Number <strong className="text-red-500">*</strong>
            </label>
            <input
              type="phone"
              value={phone}
              onChange={(v) => setPhone(v.target.value)}
              className={`border rounded px-3 py-2 text-sm text-[#140083] outline-none ${
                phoneError ? "border-red-500" : ""
              } `}
              placeholder="Your Phone Number "
            />
            <div className="text-xs text-red-500 mt-1">{phoneError}</div>
          </div>
          <div className="my-2 flex flex-col">
            <label className="font-semibold">
              Email Address <strong className="text-red-500">*</strong>
            </label>
            <input
              type="email"
              value={email}
              onChange={(v) => setEmail(v.target.value)}
              className={`border rounded px-3 py-2 text-sm text-[#140083] outline-none ${
                emailError ? "border-red-500" : ""
              } `}
              placeholder="Your Email Address"
            />
            <div className="text-xs text-red-500 mt-1">{emailError}</div>
          </div>
          <div className="my-2 flex flex-col">
            <label className="font-semibold">
              What role are you applying for?{" "}
              <strong className="text-red-500">*</strong>
            </label>
            <select
              value={role}
              className={`border rounded px-3 py-2 text-sm text-[#140083] outline-none ${
                roleError ? "border-red-500" : ""
              } `}
              onChange={(v) => setRole(v.target.value)}
            >
              <option value={""}>Select an Option</option>
              <option value={"Senior Developer"}>Senior Developer</option>
              <option value={"Senior Designer"}>Senior Designer</option>
              <option value={"Digital Marketing Manager"}>
                Digital Marketing Manager
              </option>
            </select>
            <div className="text-xs text-red-500 mt-1">{roleError}</div>
          </div>
          <div className="my-2 flex flex-col">
            <label className="font-semibold">
              Upload CV <strong className="text-red-500">*</strong>
            </label>
            <input
              type="file"
              value={fileCVValue}
              onChange={(v) => {
                setFileCV(v.target.files[0]);
                setFileCVValue(v.target.value);
              }}
              className={`border rounded px-3 py-2 text-sm text-[#140083] outline-none ${
                fileCVError ? "border-red-500" : ""
              } `}
              placeholder="Your CV"
            />
            <div className="text-xs text-red-500 mt-1">{fileCVError}</div>
          </div>
          <div className="my-5 flex flex-col items-center">
            <button
              disabled={isLoading}
              onClick={async () => {
                var check = true;

                setFirstNameError("");
                setLastNameError("");
                setPhoneError("");
                setEmailError("");
                setFileCVError("");
                setRoleError("");

                if (firstName === "") {
                  check = false;
                  setFirstNameError("This field is required.");
                }

                if (phone === "") {
                  check = false;
                  setPhoneError("This field is required.");
                }
                if (email === "") {
                  check = false;
                  setEmailError("This field is required.");
                }
                if (role === "") {
                  check = false;
                  setRoleError("This field is required.");
                }
                if (fileCV === "") {
                  check = false;
                  setFileCVError("This field is required.");
                }

                if (check) {
                  setIsLoading(true);
                  await dispatch(
                    createNewInscription({
                      first_name: firstName,
                      last_name: lastName,
                      email: email,
                      phone: phone,
                      file_cv: fileCV,
                      role: role,
                    })
                  );
                  setIsLoading(false);
                } else {
                  toast.error("Some fields are required please check");
                }
              }}
              className="font-semibold text-white bg-[#140083] rounded-md px-20 py-2"
            >
              {isLoading ? "Loading.." : "Send"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplyCareerScreen;
