// import logo from "./logo.svg";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomeScreen from "./screen/HomeScreen";
import ApplyCareerScreen from "./screen/ApplyCareerScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/apply-careers",
    element: <ApplyCareerScreen />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
