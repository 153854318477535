import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createInscriptionReducer } from "./reducers/careerReducers";

const reducer = combineReducers({
  // career
  createInscription: createInscriptionReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfoCareerAynnaka")
  ? JSON.parse(localStorage.getItem("userInfoCareerAynnaka"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
