import axios from "../../axios";

import {
  //
  CAREER_ADD_REQUEST,
  CAREER_ADD_SUCCESS,
  CAREER_ADD_FAIL,
  //
} from "../constants/careerConstants";

export const createNewInscription = (career) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAREER_ADD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const { data } = await axios.post(`/users/create-career/`, career, config);

    dispatch({
      type: CAREER_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAREER_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
